const bryanRoofArticle =
  'cookscountry/articles/6832-bryan-roof-learns-the-secrets-to-birria-at-rollies-mexican-patio-in-tucson-arizona';

const ccoHomePageData = {
  brandImageGrids: {
    bigCoastToCoast: {
      fullPortrait: {
        altText: 'Cinnamon Sugar Fried Sweet Potatoes',
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Country/Coast to Coast/Desktop/Desktop-1.jpg',
      },
      fullLandscape: {
        altText: 'Bryan Roof, talks with Janice Dulce',
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Country/Coast to Coast/Desktop/Desktop-2.jpg',
      },
      square1: {
        altText: 'Spaghetti and Clams',
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Country/Coast to Coast/Desktop/Desktop-3.jpg',
      },
      square2: {
        altText: 'La Vina Cheesecake',
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Country/Coast to Coast/Desktop/Desktop-4.jpg',
      },
    },
    bigBakedInTheOven: {
      fullPortrait: {
        altText: 'Morgan Bolling pushes a raspberry-rose hip sauce',
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Country/Baked in the Oven/Desktop/desktop-1.jpg',
      },
      fullLandscape: {
        altText: 'Sean Sherman Recipe Cook off',
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Country/Baked in the Oven/Desktop/desktop-2.jpg',
      },
      square1: {
        altText: 'Twice cooked pork',
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Country/Baked in the Oven/Desktop/desktop-3.jpg',
      },
      square2: {
        altText: 'Apple cider donuts',
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Country/Baked in the Oven/Desktop/desktop-4.jpg',
      },
    },
  },
  brandHeroes: {
    bhRecipeYouLove: {
      altText: 'The Recipes You Love and the Stories Behind Them',
      cloudinaryIds: {
        desktop: '/2023 Brand Pages/Cook%27s Country/Heroes/Desktop-Hero.jpg',
        tablet: '/2023 Brand Pages/Cook%27s Country/Heroes/Tablet-Hero',
        mobile: '/2023 Brand Pages/Cook%27s Country/Heroes/Mobile-Hero_2.jpg',
      },
    },
    bhResearch: {
      altText: 'Research that uncovers the historic roots of American recipes',
      cloudinaryIds: {
        desktop:
          '/2023 Brand Pages/Cook%27s Country/Image Break - Research that uncovers/Desktop-Image_Break_1.jpg',
        tablet:
          '/2023 Brand Pages/Cook%27s Country/Image Break - Research that uncovers/Tablet-Image_Break_1_2.jpg',
        mobile:
          '/2023 Brand Pages/Cook%27s Country/Image Break - Research that uncovers/Mobile-Image_Break_1_2.jpg',
      },
    },
    bhAmericasFavorite: {
      altText: "Welcome to the Cook's Country Table",
      cloudinaryIds: {
        desktop:
          '/2023 Brand Pages/Cook%27s Country/Americas Favorite-Image Break 2/desktop-americasfavorite-imagebreak.jpg',
        tablet:
          '/2023 Brand Pages/Cook%27s Country/Americas Favorite-Image Break 2/tablet-americasfavorite-imagebreak.jpg',
        mobile:
          '/2023 Brand Pages/Cook%27s Country/Americas Favorite-Image Break 2/Mobile-americasfavorite-imagebreak.jpg',
      },
    },
  },
  fullWidthContentBlocks: {
    fwbCoastToCoast: {
      header:
        'From Coast to Coast: Recipes and Stories That Inspire You to Cook',
      content:
        'Cook’s Country feeds your passion for cooking by introducing you to the history and people behind America’s favorite regional dishes. Our failproof, easy-to-follow recipes inspire confidence in the kitchen.',
    },
    fwcbBakedInTheOven: {
      header: 'Why You Should Trust Our Recipes',
      content:
        "We're a proud product of America's Test Kitchen's rigorous and scientific recipe development process. We make recipes that work—no hit or miss, no drama. Our recipes have been tweaked, prodded, and perfected until they're failproof for any kitchen.",
    },
  },
  fullWidthHeadlines: {
    fwhWatchAndCook: {
      header: 'Watch and Cook with Us',
    },
  },
  fullWidthCarousel: {
    fwcImages: [
      {
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Country/Watch and Cook With Us/Desktop/CC-Mag-1.jpg',
        altText: "Cook's Country Magazines",
      },
      {
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Country/Watch and Cook With Us/Desktop/CC-Mag-2.jpg',
        altText: "Cook's Country Magazine Closeup",
      },
      {
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Country/Watch and Cook With Us/Desktop/CC-Mag-3.jpg',
        altText: "Person Reading Cook's Country Magazine",
      },
      {
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Country/Watch and Cook With Us/Desktop/CC-Mag-4.jpg',
        altText: "Cook's Country Magazine Owami Article",
      },
      {
        cloudinaryId:
          '/2023 Brand Pages/Cook%27s Country/Watch and Cook With Us/Desktop/CC-Mag-5.jpg',
        altText: "Cook's Country Magazine Tomato Article",
      },
    ],
  },
  fullWidthVideo: {
    fwvWatchAndCook: {
      zypeIdSource: '64fb207829b4eb00019db28f',
    },
    fwvOnTheRoad: {
      zypeIdSource: '652957040250f70001add128',
    },
    fwvToniTipton: {
      zypeIdSource: '64fb216929b4eb00019db31a',
    },
  },
  regionalRecipesCarousel: [
    {
      cloudinaryId: '/43295-sfs-greek-chicken-37',
      headline: 'Greek Chicken',
      link: '/recipes/11396-greek-chicken',
    },
    {
      cloudinaryId: '/41719-sfs-texas-barbecue-brisket-cover-42',
      headline: 'Texas Barbecue Brisket',
      link: '/recipes/11093-texas-barbecue-brisket',
    },
    {
      cloudinaryId: '/SFS_BananaPuddingPie-187_llqx4i',
      headline: 'Banana Pudding Pie',
      link: '/recipes/12523-banana-pudding-pie',
    },
    {
      cloudinaryId: '/SFS_LardFriedChicken-68_efatl9',
      headline: 'Lard-Fried Chicken',
      link: '/recipes/12948-lard-fried-chicken',
    },
    {
      cloudinaryId: '/SFS_BarbecueRibs_53_rfxzl1',
      headline: 'Kansas City–Style Barbecue Ribs',
      link: '/recipes/13918-kansas-city-style-barbecue-ribs',
    },
    {
      cloudinaryId: '/SFS_ChicagoDeepDishPizza-294_ffkfs3',
      headline: 'Chicago Deep-Dish Pizza',
      link: '/recipes/15637-chicago-deep-dish-pizza',
    },
    {
      cloudinaryId: '/SFS_CubanSandwiches_136_avbid0',
      headline: 'Cuban Sandwiches',
      link: '/recipes/12270-cuban-sandwiches',
    },
    {
      cloudinaryId: '/SFS_SmokedCitrusChicken_015_u8o3oq',
      headline: 'Smoked Citrus Chicken',
      link: '/recipes/14794-smoked-citrus-chicken',
    },
    {
      cloudinaryId: '/37278_sfs-tater-tot-hotdish-22',
      headline: 'Tater Tot Hotdish',
      link: '/recipes/10044-tater-tot-hotdish',
    },
    {
      cloudinaryId: '/SFS_QuesabirriaTacos-102_mdfc7i',
      headline: 'Quesabirria Tacos',
      link: '/recipes/15407-quesabirria-tacos',
    },
    {
      cloudinaryId: '/SFS_BlueberryBiscuits_137_o7pfjz',
      headline: 'Blueberry Biscuits',
      link: '/recipes/13791-blueberry-biscuits',
    },
    {
      cloudinaryId: '/SFS_Chopped-Cheese-Sandwiches-61_t6xnct',
      headline: 'Chopped Cheese Sandwiches',
      link: '/recipes/11927-chopped-cheese-sandwiches',
    },
  ],
  mostPopularCarousel: [
    {
      cloudinaryId: '/40174_sfs-strawberry-cheesecake-bars-3',
      headline: 'Strawberry Cheesecake Bars',
      link: '/recipes/10864-strawberry-cheesecake-bars',
    },
    {
      cloudinaryId: '/SFS_PickleBrinedFriedChickenSandwiches-22_b09thj',
      headline: 'Pickle-Brined Fried Chicken Sandwiches',
      link: '/recipes/15412-pickle-brined-fried-chicken-sandwiches',
    },
    {
      cloudinaryId: '/32114_sfs-pasta-with-sausage-ragu-31',
      headline: 'Pasta with Sausage Ragu',
      link: '/recipes/9148-pasta-with-sausage-ragu',
    },
    {
      cloudinaryId: 'SFS_PomegranateChickenBaharatSweetPotatoes_-21_ybbuuo',
      headline: 'Pomegranate Chicken with Baharat Sweet Potatoes',
      link: '/recipes/15440-pomegranate-chicken-with-baharat-sweet-potatoes',
    },
    {
      cloudinaryId: '/SFS_BrunchBurgers_082_webtho',
      headline: 'Brunch Burgers',
      link: '/recipes/14637-brunch-burgers',
    },
    {
      cloudinaryId: '/43332-sfs-clementine-cake-cover-reshoot-80',
      headline: 'Clementine Cake',
      link: '/recipes/11371-clementine-cake',
    },
    {
      cloudinaryId: '/SFS_ItalianHoagieSalad-43_lyyunb',
      headline: 'Italian Hoagie Salad',
      link: '/recipes/15647-italian-hoagie-salad',
    },
    {
      cloudinaryId: '/SFS_SalmonPiccata_37_xwji2y',
      headline: 'Salmon Piccata',
      link: '/recipes/13772-salmon-piccata',
    },
    {
      cloudinaryId: 'SFS_DinerStylePattyMelts_162_lqskhb',
      headline: 'Diner-Style Patty Melts',
      link: '/recipes/14928-diner-style-patty-melts',
    },
    {
      cloudinaryId: '29125_sfs-chocolate-eclair-cake-029',
      headline: 'Chocolate Eclair Cake',
      link: '/recipes/6576-chocolate-eclair-cake',
    },
    {
      cloudinaryId: '/SFS_SpaghettiAndClams-70_qrtpit',
      headline: 'Spaghetti with Clams',
      link: '/recipes/15414-spaghetti-with-clams',
    },
    {
      cloudinaryId: '/SFS_SmoresPie_33_f7jbgt',
      headline: "S'Mores Pie",
      link: '/recipes/15792-s-mores-pie',
    },
  ],
  teamMemberCarouselItems: {
    title: 'Meet the Team',
    authorCards: [
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_toni_tipton_martin',
        description:
          "Toni is the editor in chief of <i>Cook's Country</i>. She is a two-time James Beard Award winner for her books on African American cooking.",
        name: 'Toni Tipton-Martin',
        path: 'authors/185',
      },
      {
        cloudinaryId: '/Play Cast Headshots/staff_bryan_roof',
        description:
          "Bryan is the editorial director (and intrepid On-the-Road traveler) of <i>Cook's Country</i>, as well as a longtime cast member of <i>Cook's Country</i> TV.",
        name: 'Bryan Roof',
        path: 'authors/48',
      },
      {
        cloudinaryId:
          '/AKO%20Articles/Author_Headshots/staff_morgan_bolling.jpg',
        description:
          "Morgan is the executive editor of creative content for <i>Cook's Country</i> and a cast member of <i>Cook's Country</i> TV. She loves all things barbecue and pork.",
        name: 'Morgan Bolling',
        path: 'authors/47',
      },
      {
        cloudinaryId: '/Play Cast Headshots/staff_lawman_johnson',
        description:
          "Lawman is a senior editor for <i>Cook's Country</i> and a <i>Cook's Country</i> TV cast member. He worked in restaurants and taught cooking classes before joining ATK.",
        name: 'Lawman Johnson',
        path: 'authors/49',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_mark_huxsoll',
        description:
          "Mark is a test cook for <i>Cook's Country</i>. He's a Culinary Institute of America grad who's cooked in Philly, Chicago, and Copenhagen.",
        name: 'Mark Huxsoll',
        path: 'authors/192',
      },
      {
        cloudinaryId: 'AKO Articles/Author_Headshots/staff_scott_kathan',
        description:
          "Scott is the executive editor of <i>Cook's Country</i>. He's cooked in professional kitchens and has a background writing about arts and pop culture.",
        name: 'Scott Kathan',
        path: 'authors/126',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_kelly_song',
        description:
          "Kelly is a test cook for <i>Cook's Country</i>. She loves tending to a slow braise and always has room for chocolate cake.",
        name: 'Kelly Song',
        path: 'authors/215',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_amanda_luchtel',
        description:
          "Amanda is a test cook for <i>Cook's Country</i>. She's a former private chef and has a certificate in sustainable farming.",
        name: 'Amanda Luchtel',
        path: 'authors/161',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_jessica_rudolph',
        description:
          "Jessica is an associate editor for <i>Cook's Country</i>. She’s a culinary school grad and specializes in weeknight dinner recipes.",
        name: 'Jessica Rudolph',
        path: 'authors/193',
      },
      {
        cloudinaryId:
          '/AKO Articles/Author_Headshots/staff_Nicole_Konstantinakos_23',
        description:
          "Nicole is the deputy food editor for <i>Cook's Country</i>. A former sous chef, she spent years cooking and teaching in the Basque Country.",
        name: 'Nicole Konstantinakos',
        path: 'authors/163',
      },
      {
        cloudinaryId: 'AKO Articles/Author_Headshots/staff_matthew_fairman',
        description:
          "Matthew is a senior editor for <i>Cook's Country</i>. He's a former restaurant cook and college writing teacher. He lives in New Orleans.",
        name: 'Matthew Fairman',
        path: 'authors/105',
      },
      {
        cloudinaryId: '/AKO Articles/Author_Headshots/staff_steve_klise',
        description:
          "Steve is a senior staff photographer at <i>America's Test Kitchen</i> and the chief photographer for <i>Cook's Country</i>, both in the studio and on the road.",
        name: 'Steve Klise',
        path: 'authors/68',
      },
    ],
  },
  twoUpCarousels: {
    tucStoryOfFood: [
      {
        href: '/cookscountry/articles/2332-on-the-road-the-titan-of-teriyaki',
        images: {
          cloudinaryId: '/CCO/Web Articles/CAN_WA_2018_2494',
          altText: 'The Titan of Teriyaki',
        },
        headline:
          'A stalwart of the Seattle restaurant scene invites us into his kitchen.',
        description:
          'When Seattle cooking legend Toshi Kasahara invited me for a teriyaki lunch at his restaurant in Mill Creek, Washington, about 30 minutes outside of the city, I hadn’t pictured a strip-mall storefront with just three tables and a view of Staples and Rite Aid out front. But that’s where I find him, tucked away in the tiny kitchen of Toshi’s Teriyaki Grill, a space filled with the iconic aromas of ginger, garlic, and grilled meats. Toshi arrived in Seattle in 1976 with a business degree and a plan: to change the city’s understanding of what teriyaki should be.',
        authors: [
          {
            id: 1,
            firstName: 'Bryan',
            lastName: 'Roof',
            image: {
              altText: 'Bryan Roof Headshot',
              url: '/AKO Articles/Author_Headshots/staff_bryan_roof.jpg',
            },
          },
        ],
      },
      {
        href: '/cookscountry/articles/3243-on-the-road-attitude-and-smoke',
        images: {
          cloudinaryId: '/CCO/On the Road/Attitude and Smoke/CAN_MO_2019_9433',
          altText: 'Attitude and Smoke',
        },
        headline:
          'In this small town, there’s a chef making a name for his Kansas City BBQ.',
        description:
          'As I pull into the gravel parking lot of Crane Brewing in Raytown, Missouri, I spy a 375-gallon offset smoker kicking off a faint trickle of smoke. I step inside the brewery and work my way past the bar area and across the production floor and eventually take my place in line with the small crowd gathered in the barrel room, flanked by giant wooden vats of beer. I was ready for my first taste of Harp Barbecue. Owner Tyler Harp dons an apron and positions himself behind a cutting board, ready to slice ribs and brisket to order.',
        authors: [
          {
            id: 1,
            firstName: 'Bryan',
            lastName: 'Roof',
            image: {
              altText: 'Bryan Roof Headshot',
              url: '/AKO Articles/Author_Headshots/staff_bryan_roof.jpg',
            },
          },
        ],
      },
      {
        href: '/cookscountry/articles/2076-on-the-road-in-tampa-home-of-the-cuban-sandwich',
        images: {
          cloudinaryId: 'CAN_FL_2019_8953_1_hshvcq',
          altText: 'In Tampa, Home of the Cuban Sandwich',
        },
        headline:
          'A visit to Ybor City reveals the complex history of the Cuban sandwich.',
        description:
          "In Tampa, Florida, the Cuban sandwich is an enduring source of pride, sustenance, and fierce debate. Connoisseurs take strong stands on each element. Cuban sandwiches are derived from mixto sandwiches, which were popular in Cuba over a century ago. “As the sandwich emigrated from Cuba to Key West and eventually to Tampa, the name evolved from 'mixto' to 'Cubano' and 'Cuban,’” says historian Jeff Houck. The sandwich reflects the cultural makeup of the Ybor City community, including Spanish, Cuban, German, Jewish, and American influences.",
        authors: [
          {
            id: 1,
            firstName: 'Bryan',
            lastName: 'Roof',
            image: {
              altText: 'Bryan Roof Headshot',
              url: '/AKO Articles/Author_Headshots/staff_bryan_roof.jpg',
            },
          },
        ],
      },
      {
        href: '/cookscountry/articles/6087-cooks-countrys-bryan-roof-explores-the-rustic-mountain-food-of-puerto-rico',
        images: {
          cloudinaryId:
            '/CCO/2022 Articles /August 2022/CCO-WebAssets-GuanimesHero.jpg',
          altText: 'Rustic Mountain Food of Puerto Rico',
        },
        headline:
          'At Casa Vieja, guanimes con bacalao is a simple but storied dish.',
        description:
          "The road to the mountain town of Ciales, near the center of Puerto Rico, snakes upward as it cuts through lush green forest. Casa Vieja appears, almost suddenly, like a bright blue beacon of roadside dining on a sharp bend when you thought you might have passed the last remaining restaurant miles ago. Overlooking the verdant Toro Negro Forest, Casa Vieja couldn't be in a more perfect setting.",
        authors: [
          {
            id: 1,
            firstName: 'Bryan',
            lastName: 'Roof',
            image: {
              altText: 'Bryan Roof Headshot',
              url: '/AKO Articles/Author_Headshots/staff_bryan_roof.jpg',
            },
          },
        ],
      },
    ],
  },
  twoColumnTexts: {
    tctMagazineCarousel: {
      description:
        "<i>Cook's Country</i> magazine is a celebration of cooking in America. We empower home cooks with the skills, knowledge, and confidence needed to become successful in the kitchen. ",
      headline: 'Cook’s Country Magazine',
      ctaLinks: [
        {
          className: 'atk-button-styles',
          linkText: 'Subscribe & Save 58%',
          linkHref:
            'https://cookscountry.americastestkitchen.com/servlet/OrdersGateway?cds_mag_code=CCY&cds_page_id=265426&cds_response_key=IWK23JHR1',
          target: '_blank',
        },
        {
          linkText: 'Browse Issues',
          linkHref: '/cookscountry/magazines',
        },
      ],
    },
    tctEpisodeDetails: {
      description:
        "<i>Cook’s Country</i> TV features America's best regional home cooking and relies on a practical, no-nonsense approach to food. Watch and learn as we demonstrate the techniques behind classic and innovative dishes while celebrating the cooks who make them.",
      headline: 'Cook’s Country TV',
      ctaLinks: [
        {
          linkHref: '/cookscountry/episodes',
          linkText: 'Watch All 16 Seasons',
        },
      ],
    },
    tctTasteOfCooks: {
      description:
        'Our test kitchen team brings you accessible, thoroughly tested weeknight meals plus local specialties from every corner of the country.',
      headline: "<em>A Taste</em> of Cook's Country",
    },
    tctOnTheRoad: {
      description:
        'Follow along with <i>Cook’s Country</i> Editorial Director Bryan Roof as he travels the country meeting cooks and eating the best of what America has to offer.',
      headline: 'On the Road with Bryan Roof',
      ctaLinks: [
        {
          linkHref: '/cookscountry/collections/ontheroad',
          linkText: 'Watch Episodes',
        },
      ],
    },
    tctPortraits: {
      description:
        'We crisscross the country to meet and learn from great American cooks. Their stories are a testament to our diverse and ever-changing culinary history.',
      headline: 'Portraits of the People <span>Who Feed Us</span>',
    },
    tctToniTipton: {
      description:
        'Every recipe has a story. Join <i>Cook’s Country</i> Editor in Chief Toni Tipton-Martin as she dives into history that will inspire you to cook.',
      headline: 'In the Library with <span>Toni Tipton-Martin</span>',
    },
  },
  ProcessGrid: [
    {
      title: 'Portraits of the People Who Feed Us',
      cardDeck: [
        {
          header: 'Mateo Otero',
          content: `Rollies Mexican Patio, Tucson, Arizona. &#34;I had the food truck concept in my mind, but it accidentally turned into a restaurant.&#34; <a data-mx='{link_name: 'Bryan Roof Article', destination_URL: ${bryanRoofArticle}}' href=${bryanRoofArticle}>Read more about Otero</a>.`,
          cloudinaryId:
            '/2023 Brand Pages/Cook%27s Country/Portraits of People/mateo.png',
        },
        {
          header: 'Meherwan Irani',
          content:
            "Chai Pani, Asheville, North Carolina. &#34;At what point do I stop saying that I'm an Indian living in the South and start saying that I'm a Southerner that happens to be Indian?&#34; <a data-mx='{link_name: 'Irani Article', destination_URL: '/cookscountry/articles/2629-on-the-road-an-asheville-staple'}' href='/cookscountry/articles/2629-on-the-road-an-asheville-staple'>Read more about Irani</a>.",
          cloudinaryId:
            '/2023 Brand Pages/Cook%27s Country/Portraits of People/mehe.png',
        },
        {
          header: 'Rodney Scott',
          content:
            "Rodney Scott's BBQ, Charleston, South Carolina. &#34;Every day is a good day.&#34; <a data-mx='{link_name: 'Scott Article', destination_URL: '/cookscountry/articles/3676-the-pride-of-the-pit'}' href='/cookscountry/articles/3676-the-pride-of-the-pit'>Read more about Scott</a>.",
          cloudinaryId:
            '/2023 Brand Pages/Cook%27s Country/Portraits of People/rodney.png',
        },
        {
          header: 'Janice Dulce',
          content:
            "<abbr>FOB</abbr> Kitchen, Oakland, California. &#34;No one was treating Filipino food like that, using quality ingredients.&#34; <a data-mx='{link_name: 'Dulce Article', destination_URL: '/cookscountry/articles/6300-cooks-countrys-bryan-roof-learns-the-secrets-to-sinigang-at-fob-kitchen'}' href='/cookscountry/articles/6300-cooks-countrys-bryan-roof-learns-the-secrets-to-sinigang-at-fob-kitchen'>Read more about Dulce</a>.",
          cloudinaryId:
            '/2023 Brand Pages/Cook%27s Country/Portraits of People/janice.png',
        },
      ],
    },
  ],
  testimonials: [
    {
      id: 0,
      quote: 'Recipes that always, always work',
      citation: '- The New York Times',
    },
    {
      id: 1,
      quote:
        'Trust is the reason why America’s Test Kitchen’s cooking shows reach more than four million viewers.',
      citation: '- CBS News Sunday Morning',
    },
    {
      id: 2,
      quote: 'Far and away the best cooking program on television today.',
      citation: '- <span>Slate.com</span>',
    },
    {
      id: 3,
      quote: 'The two best magazines dealing with home cooking.',
      citation: '- The Wall Street Journal',
    },
  ],
};

export default ccoHomePageData;
